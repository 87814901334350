
import { store } from 'quasar/wrappers'
import { createStore } from 'vuex';
import { app } from "./app.js"
import { auth } from "./auth.js"
import { audits } from "./audits.js"
import { brandcars } from "./brandcars.js"
import { cars } from "./cars.js"
import { cars_history } from "./cars_history.js"
import { clients } from "./clients.js"
import { customers } from "./customers.js"
import { extra_features } from "./extra_features.js"
import { locations } from "./locations.js"
import { model_has_permissions } from "./model_has_permissions.js"
import { model_has_roles } from "./model_has_roles.js"
import { orders } from "./orders.js"
import { permissions } from "./permissions.js"
import { publiccars } from "./publiccars.js"
import { rent } from "./rent.js"
import { role_has_permissions } from "./role_has_permissions.js"
import { roles } from "./roles.js"
import { transactions } from "./transactions.js"
import { transactions2 } from "./transactions2.js"
import { users } from "./users.js"
/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Store instance.
 */

export default store(function (/* { ssrContext } */) {
  const Store = createStore({
    modules: {
      app,
      auth,
		audits,
		brandcars,
		cars,
		cars_history,
		clients,
		customers,
		extra_features,
		locations,
		model_has_permissions,
		model_has_roles,
		orders,
		permissions,
		publiccars,
		rent,
		role_has_permissions,
		roles,
		transactions,
		transactions2,
		users
    },
    // enable strict mode (adds overhead!)
    // for dev mode and --debug builds only
   // strict: process.env.DEBUGGING
  })
  return Store
})